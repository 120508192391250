/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import useService from '~/src/UserInterface/App/composables/Container/useService'

/**
 * This middleware must be called on data requestor auth page
 *
 * This middleware checks if login and data request query exists in route
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const logger = useService('logger')
  logger.info('[DATA_REQUEST_AUTH] Middleware fired')

  if (typeof to.params.email !== 'string' || typeof to.params.loginUrl !== 'string') {
    logger.error(
      '[DATA_REQUEST_AUTH] Route does not have the correct url parameters. Redirecting to error page.',
    )

    // eslint-disable-next-line ts/no-unsafe-argument
    return navigateTo(useNuxtApp().$localePath('dataRequest-Error'))
  }
})
